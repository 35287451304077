/* ––––––––––MODULE-INTRO–––––––––– */
.intro-cover {
  line-height: 0;
}

.intro-cover img {
  height: auto;
}

.intro-card::before {
  content: " ";
  width: 100%;
  display: block;
  position: absolute;
  background-color: $color-beige-soft;
  height: $radius-small + 1px;
  top: $radius-small * -1;
  border-top-left-radius: $radius-small;
  border-top-right-radius: $radius-small;
}

.intro-card {
  position: relative;
  padding: 20px 0 52px 0;

  background-color: $color-beige-soft;
  border-bottom-left-radius: $radius-small;
  border-bottom-right-radius: $radius-small;
}

.module-intro .slider {
  margin-top: 32px;
}

.module-intro .arrow {
  display: none;
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 768px) {
  .intro-card::before {
    height: $radius-large + 1px;
    top: $radius-large * -1;
    border-top-left-radius: $radius-large;
    border-top-right-radius: $radius-large;
  }

  .intro-card {
    border-bottom-left-radius: $radius-large;
    border-bottom-right-radius: $radius-large;
  }

  .module-intro .slider {
    margin-top: 52px;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1024px) {
  .intro-cover {
    width: 100%;
  }

  .intro-cover img {
    width: 100%;
    max-width: 100%;
    max-height: 80vh;
    object-fit: cover;
    object-position: top center;
    overflow: hidden;
  }

  .intro-card {
    padding: 52px 0 72px 0;
  }

  .module-intro .slider {
    margin-top: 72px;
  }

  .module-intro .slider-element {
    width: 100%;
    margin: 0;
  }

  .slider-element.hidden {
    display: none;
  }

  .module-intro .slider {
    position: relative;
    overflow: visible;
  }

  .module-intro .arrow {
    display: block;
    width: 48px;
    position: absolute;
    top: calc(50% - 24px);
    cursor: pointer;
  }

  .module-intro circle.arrowBG {
    transition: fill $duration-animation;
  }
  .module-intro .arrow:hover circle.arrowBG {
    fill: $color-white-hover;
  }

  .module-intro .arrow.prev {
    left: -48px - 20px;
  }

  .module-intro .arrow.next {
    right: -48px - 20px;
  }

  .slider-element {
    animation-name: fade;
    animation-duration: 0.5s;
  }

  @keyframes fade {
    from {
      opacity: 0.7;
    }
    to {
      opacity: 1;
    }
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1440px) {
}
