/* ––––––––––MODULE-TEAM–––––––––– */
ul.team-list {
  margin-top: 12px;
}

.team-element {
  line-height: 0;
}

.team-element .team-name {
  margin-top: 12px;
}

// .team-element {
//   width: 60%;
// }

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 768px) {
  ul.team-list {
    margin-top: 32px;
  }

  .team-element {
    width: 33%;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1024px) {
  .module-team .container .kirbytext {
    max-width: unset;
    padding: 0;
  }

  .module-team .slider-wrapper {
    white-space: inherit;
    display: grid;
    gap: 32px 24px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .team-element {
    width: 100%;
    margin: 0;
  }

  .team-element:first-of-type,
  .team-element:last-of-type {
    margin-right: 0;
    margin-left: 0;
  }

  .team-function {
    word-break: break-all;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1440px) {
  .module-team .slider-wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .team-function {
    font-size: 19px; //little cheat
  }
}
