/* ––––––––––MODULE-ERROR–––––––––– */
.module-error {
  min-height: 75vh;
  width: 100%;
  display: flex;
  align-items: center;

  //   background-color: $color-beige-soft;
}

.module-error .container {
  width: 100%;
}

.module-error .kirbytext {
  text-align: center;
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 768px) {
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1024px) {
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1440px) {
}
