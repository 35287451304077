/* ––––––––––VARS–––––––––– */
$color-black: #000000;
$color-beige: #eae3d2;
$color-beige-soft: #f1ede2;
$color-lila: #a6a8d5;
$color-lila-soft: #cacbe6;
$color-yellow: #ffefaf;
$color-white: #ffffff;
$color-white-hover: #fafafa;
$color-white-active: #ffffff;
$radius-small: 12px;
$radius-medium: 24px;
$radius-large: 32px;
$duration-animation: 0.2s;
$duration-animation-long: 0.3s;
