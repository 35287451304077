/* ––––––––––BASIC ELEMENTS–––––––––– */
section.module-text,
section.module-team,
section.module-accordeon,
section.module-image-text-button {
  margin-top: 52px;
}

.slider {
  width: 100%;
  height: auto;
  overflow-x: scroll;
  position: relative;
}

.slider-wrapper {
  white-space: nowrap;
}

.slider-element {
  width: 72%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-left: 12px;
}

.slider-element:first-of-type {
  margin-left: 20px;
}

.slider-element:last-of-type {
  margin-right: 20px;
}

.slider::-webkit-scrollbar {
  display: none;
}

.slider-element img {
  width: 100%;
  height: auto;
}

.button-wrap {
  text-align: center;
}

.button {
  display: inline-block;

  margin-top: 32px;
  padding: 16px 32px;
  border-radius: 28px;
  overflow: overlay;

  text-decoration: none;
  transition: background-color $duration-animation;
}

.button-primary {
  background-color: $color-white;
}

.button-primary:hover {
  background-color: $color-white-hover;
}

.button-secondary {
  background-color: $color-lila-soft;
}

.button-secondary:hover {
  background-color: $color-lila;
}

.button:focus,
.button:focus-visible {
  box-shadow: 0 0 0 2px $color-lila;
}

.button:active {
  background-color: $color-white-active;
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 768px) {
  section.module-text,
  section.module-team,
  section.module-accordeon,
  section.module-image-text-button {
    margin-top: 92px;
  }

  .slider-element {
    width: 72%;
    margin-left: 24px;
  }

  .slider-element:first-of-type {
    margin-left: 72px;
  }

  .slider-element:last-of-type {
    margin-right: 72px;
  }

  .button {
    margin-top: 52px;
    padding: 20px 40px;
    border-radius: 35px;
  }

  .button:focus,
  .button:focus-visible {
    box-shadow: 0 0 0 4px $color-lila;
  }

  /* ––––––––––MODULE-INTRO–––––––––– */
  .intro-card::before {
    height: $radius-large + 1px;
    top: $radius-large * -1;
    border-top-left-radius: $radius-large;
    border-top-right-radius: $radius-large;
  }

  .intro-card {
    padding: 40px 0 72px 0;

    border-bottom-left-radius: $radius-large;
    border-bottom-right-radius: $radius-large;
  }

  .module-intro .slider {
    margin-top: 32px;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1024px) {
  section.module-text,
  section.module-team,
  section.module-accordeon,
  section.module-image-text-button {
    margin-top: 124px;
  }

  //NEW ELEMENTS
  .container {
    padding: 0 92px;
    margin: auto;
    max-width: 1080px;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1440px) {
}
