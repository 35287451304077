/* ––––––––––MODULE-ACCORDEON–––––––––– */
.module-accordeon .container {
  margin-left: 20px;
  margin-right: 20px;
  background-color: $color-beige-soft;
  border-radius: $radius-medium;

  transition: padding $duration-animation-long ease-in-out;
}

.accordeon-header {
  padding: 12px 20px;
  position: relative;

  cursor: pointer;
  transition: padding $duration-animation-long ease-in-out;
}

.module-accordeon .accordeon-arrow {
  position: absolute;
  top: 24px;
  width: 24px;

  transition: all $duration-animation-long ease-in-out;
}

.module-accordeon .accordeon-title {
  display: inline;
  margin-left: 32px;
}

.module-accordeon .accordeon-content {
  overflow: hidden;
  max-height: 0;
  transition: max-height $duration-animation-long ease-in-out;
}

.module-accordeon .kirbytext {
  padding: 0 20px 0 20px;
  transition: padding $duration-animation-long ease-in-out;
}

// Active State
.module-accordeon.active .accordeon-header {
  padding: 24px 20px 0 20px;
}

.module-accordeon.active .accordeon-arrow {
  top: 36px;
  transform: rotate(90deg);
}

.module-accordeon.active .container {
  padding-bottom: 32px;
}

.module-accordeon.active .accordeon-content {
  display: block;
  max-height: 500px;
}

.module-accordeon.active .kirbytext {
  padding-top: 24px;
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 768px) {
  .module-accordeon .container {
    margin-left: 72px;
    margin-right: 72px;
  }

  .module-accordeon.active .accordeon-header {
    padding-top: 32px;
  }

  .module-accordeon .accordeon-arrow {
    top: 24px;
    width: 32px;
  }

  .module-accordeon .accordeon-title {
    margin-left: 38px;
  }

  .module-accordeon .kirbytext {
    padding-top: 0;
  }

  // Active State
  .module-accordeon.active .container {
    padding-bottom: 52px;
  }

  .module-accordeon.active .kirbytext {
    padding-top: 32px;
  }

  .module-accordeon.active .accordeon-arrow {
    top: 44px;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1024px) {
  .module-accordeon {
    padding: 0 92px;
  }

  .accordeon-header {
    box-sizing: border-box;
    padding: 24px 72px;
  }

  .module-accordeon .accordeon-arrow {
    top: 41px;
  }

  .module-accordeon .accordeon-limit {
    max-width: 902px;
    margin: auto;
  }

  .module-accordeon .container {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    margin: auto;
    box-sizing: border-box;
  }

  .module-accordeon .accordeon-content {
    padding: 0 72px 0 72px;
  }

  .module-accordeon .kirbytext {
    margin: auto;
    max-width: 902px;
    padding: 0;
  }

  // Active State
  .module-accordeon.active .accordeon-header {
    padding: 52px 72px 0 72px;
  }

  .module-accordeon.active .accordeon-arrow {
    top: 68px;
  }

  .module-accordeon.active .container {
    padding-bottom: 72px;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1440px) {
}
