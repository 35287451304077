/* ––––––––––NAV–––––––––– */
html {
  scroll-padding-top: 94px; //correct anchor links
}

main {
  margin-top: 40px;
}

nav.main-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: space-between;

  background-color: $color-beige-soft;
  border-bottom: 0.75px solid $color-black;
}

nav.main-nav a {
  display: block;
  padding: 7px 20px 6px 20px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
}

#muka-logo {
  display: block;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 10px;
  width: 60px;
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 768px) {
  html {
    scroll-padding-top: 132px; //correct anchor links
  }

  main {
    margin-top: 57px;
  }

  nav.main-nav {
    border-bottom-width: 1px;
  }

  nav.main-nav a {
    padding: 10px 32px;
    font-size: 24px;
  }

  #muka-logo {
    margin-top: 16px;
    width: 82px;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1024px) {
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1440px) {
}
