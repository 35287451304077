/* ––––––––––MODULE-TEXT–––––––––– */

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 768px) {
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1024px) {
  .module-text {
    padding: 0 92px;
  }
  .module-text .container {
    background-color: $color-lila-soft;
    border-radius: $radius-large;
    padding: 52px 0 72px 0;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1440px) {
}
