// @font-face {
//   font-family: Pitagon;
//   src: url("fonts/pitagon/PitagonSansText-ExtraLight.woff2") format("woff2"),
//     url("fonts/pitagon/PitagonSansText-ExtraLight.ttf") format("truetype"),
//     url("fonts/pitagon/PitagonSansText-ExtraLight.otf") format("opentype");
//   font-weight: 200;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: Pitagon;
//   src: url("fonts/pitagon/PitagonSansText-ExtraLightItalic.woff2") format("woff2"),
//     url("fonts/pitagon/PitagonSansText-ExtraLightItalic.ttf") format("truetype"),
//     url("fonts/pitagon/PitagonSansText-ExtraLightItalic.otf") format("opentype");
//   font-weight: 200;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: Pitagon;
//   src: url("fonts/pitagon/PitagonSansText-Light.woff2") format("woff2"),
//     url("fonts/pitagon/PitagonSansText-Light.ttf") format("truetype"),
//     url("fonts/pitagon/PitagonSansText-Light.otf") format("opentype");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: Pitagon;
//   src: url("fonts/pitagon/PitagonSansText-LightItalic.woff2") format("woff2"),
//     url("fonts/pitagon/PitagonSansText-LightItalic.ttf") format("truetype"),
//     url("fonts/pitagon/PitagonSansText-LightItalic.otf") format("opentype");
//   font-weight: 300;
//   font-style: italic;
//   font-display: swap;
// }

@font-face {
  font-family: Pitagon;
  src: url("fonts/pitagon/PitagonSansText-Regular.woff2") format("woff2"),
    url("fonts/pitagon/PitagonSansText-Regular.ttf") format("truetype"),
    url("fonts/pitagon/PitagonSansText-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Pitagon;
  src: url("fonts/pitagon/PitagonSansText-Italic.woff2") format("woff2"),
    url("fonts/pitagon/PitagonSansText-Italic.ttf") format("truetype"),
    url("fonts/pitagon/PitagonSansText-Italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Pitagon;
  src: url("fonts/pitagon/PitagonSansText-Medium.woff2") format("woff2"),
    url("fonts/pitagon/PitagonSansText-Medium.ttf") format("truetype"),
    url("fonts/pitagon/PitagonSansText-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Pitagon;
  src: url("fonts/pitagon/PitagonSansText-MediumItalic.woff2") format("woff2"),
    url("fonts/pitagon/PitagonSansText-MediumItalic.ttf") format("truetype"),
    url("fonts/pitagon/PitagonSansText-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Pitagon;
  src: url("fonts/pitagon/PitagonSansText-SemiBold.woff2") format("woff2"),
    url("fonts/pitagon/PitagonSansText-SemiBold.ttf") format("truetype"),
    url("fonts/pitagon/PitagonSansText-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Pitagon;
  src: url("fonts/pitagon/PitagonSansText-SemiBoldItalic.woff2") format("woff2"),
    url("fonts/pitagon/PitagonSansText-SemiBoldItalic.ttf") format("truetype"),
    url("fonts/pitagon/PitagonSansText-SemiBoldItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

// @font-face {
//   font-family: Pitagon;
//   src: url("fonts/pitagon/PitagonSansText-Bold.woff2") format("woff2"),
//     url("fonts/pitagon/PitagonSansText-Bold.ttf") format("truetype"),
//     url("fonts/pitagon/PitagonSansText-Bold.otf") format("opentype");
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: Pitagon;
//   src: url("fonts/pitagon/PitagonSansText-BoldItalic.woff2") format("woff2"),
//     url("fonts/pitagon/PitagonSansText-BoldItalic.ttf") format("truetype"),
//     url("fonts/pitagon/PitagonSansText-BoldItalic.otf") format("opentype");
//   font-weight: 700;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: Pitagon;
//   src: url("fonts/pitagon/PitagonSansText-ExtraBold.woff2") format("woff2"),
//     url("fonts/pitagon/PitagonSansText-ExtraBold.ttf") format("truetype"),
//     url("fonts/pitagon/PitagonSansText-ExtraBold.otf") format("opentype");
//   font-weight: 800;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: Pitagon;
//   src: url("fonts/pitagon/PitagonSansText-ExtraBoldItalic.woff2")
//       format("woff2"),
//     url("fonts/pitagon/PitagonSansText-ExtraBoldItalic.ttf") format("truetype"),
//     url("fonts/pitagon/PitagonSansText-ExtraBoldItalic.otf") format("opentype");
//   font-weight: 800;
//   font-style: italic;
//   font-display: swap;
// }
