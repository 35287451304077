/* ––––––––––FOOTER–––––––––– */
footer {
  padding-top: 12px;
  padding-bottom: 52px;
  background-color: $color-beige-soft;
}

footer .kirbytext {
  margin-top: 32px;
}

// main > section:last-child.module-image-text-button + footer {
//   background-color: red !important;
// }

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 768px) {
  footer {
    padding-top: 104px;
    padding-bottom: 72px;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1024px) {
  footer {
    margin-top: -$radius-large;
    padding-top: 104px + $radius-large;
  }

  footer .container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 62px;
  }

  footer .container {
    max-width: none;
    margin-top: 0;
  }

  footer .site-impressum,
  footer .site-datenschutz {
    margin-top: 0;
    width: 50%;
  }

  footer .kirbytext {
    padding: 0;
    max-width: 540px;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1440px) {
  footer .container {
    padding: 0 124px;
  }
}
