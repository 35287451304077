/* ––––––––––MODULE-IMG TEXT BUTTON–––––––––– */
.module-image-text-button {
  line-height: 0;
}

.module-image-text-button img {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  border-top-right-radius: $radius-small;
  border-top-left-radius: $radius-small;
}

.module-image-text-button .content {
  box-sizing: border-box;
  position: relative;
  padding: 32px 0 40px 0;
  background-color: $color-lila-soft;
}

.module-image-text-button .content::after {
  content: " ";
  width: 100%;
  display: block;
  position: absolute;
  background-color: $color-lila-soft;
  height: $radius-small + 1px;
  bottom: $radius-small * -1;
  border-bottom-left-radius: $radius-small;
  border-bottom-right-radius: $radius-small;
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 768px) {
  .module-image-text-button img {
    border-top-right-radius: $radius-large;
    border-top-left-radius: $radius-large;
  }

  .module-image-text-button .content {
    padding: 52px 0 32px 0;
  }

  .module-image-text-button .content::after {
    height: $radius-large + 1px;
    bottom: $radius-large * -1;
    border-bottom-left-radius: $radius-large;
    border-bottom-right-radius: $radius-large;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1024px) {
  .module-image-text-button {
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
  }

  .module-image-text-button img {
    object-fit: cover;
    width: 50%;
    border-top-left-radius: 0;
    border-bottom-right-radius: $radius-large;
  }

  .module-image-text-button .content {
    width: 50%;
    padding: 52px 62px 32px 62px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top-left-radius: $radius-large;
    border-bottom-left-radius: $radius-large;
  }

  .module-image-text-button .content .kirbytext {
    margin: 0;
    padding: 0;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }

  .module-image-text-button .content::after {
    display: none;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1440px) {
  .module-image-text-button .content {
    padding: 52px 124px;
  }
}
