/* ––––––––––TYPOGRAPHY–––––––––– */
h1,
h2,
h3,
p,
a,
em,
strong,
span {
  color: $color-black;
  line-height: 1.5;
  font-weight: 500;
}

p,
a,
em {
  font-weight: 400;
}

a.button {
  font-weight: 500;
}

strong {
  font-weight: 600;
}

em {
  font-style: italic;
}

h1 {
  font-size: 34px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 20px;
}

p,
a,
em,
strong,
span {
  font-size: 16px;
}

/* KIRBYTEXTS */
section .kirbytext,
footer .kirbytext {
  padding: 0 20px;
}

.kirbytext h1,
.kirbytext h2,
.kirbytext h3,
.kirbytext p {
  padding-top: 24px;
}

.kirbytext h1 + p,
.kirbytext h2 + p,
.kirbytext h3 + p {
  padding-top: 12px;
}

.kirbytext > :first-child {
  padding-top: 0;
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 38px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 22px;
  }

  p,
  a,
  em,
  strong,
  span {
    font-size: 18px;
  }

  /* KIRBYTEXTS */
  section .kirbytext,
  footer .kirbytext {
    padding: 0 72px;
  }

  .kirbytext h1,
  .kirbytext h2,
  .kirbytext h3,
  .kirbytext p {
    padding-top: 32px;
  }

  .kirbytext h1 + p,
  .kirbytext h2 + p,
  .kirbytext h3 + p {
    padding-top: 24px;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1024px) {
  h1 {
    font-size: 44px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
  }

  p,
  a,
  em,
  strong,
  span {
    font-size: 20px;
  }

  /* KIRBYTEXTS */
  section .kirbytext {
    max-width: 902px;
    margin: auto;
  }

  // .kirbytext h1,
  // .kirbytext h2,
  // .kirbytext h3,
  // .kirbytext p {
  //   padding-top: 32px;
  // }

  // .kirbytext h1 + p,
  // .kirbytext h2 + p,
  // .kirbytext h3 + p {
  //   padding-top: 24px;
  // }
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: 1440px) {
}
